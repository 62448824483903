import { axiosInstance } from "./axiosInstance";
import axios from "axios";
import {SERVER_URI_API} from "./urls";
import {useNavigate} from "react-router-dom";

export const authUser = (username, password) => axios.post(`${SERVER_URI_API}/auth/login/`, {
        username: username,
        password: password,
    },
    {headers: {
        'Content-Type': 'application/json',
    }}
);

export const authTelegramUser = (telegram_id) => axios.post(`${SERVER_URI_API}/auth/telegram/login/`, {
        telegram_id: telegram_id
    },
    {headers: {
            'Content-Type': 'application/json',
        }}
);

export const registerUser = (userData) => axios.post(`${SERVER_URI_API}/auth/telegram/register/`, {
    ...userData
    },
{headers: {
    'Content-Type': 'application/json',
}}
);

export const getUserData = (userId) => {

    if (userId) {
        const response = axiosInstance.get(`auth/current_user/`, {
            params: {
                user_id: userId
            }
        });

        if (response.is_access_finished) {
            window.location.href = "/trial-end";
        }

        return response;

    } else {
        const response = axiosInstance.get(`auth/current_user/`);

        if (response.is_access_finished) {
            window.location.href = "/trial-end";
        }

        return response;

    }
};