import React, {useEffect, useState} from "react";
import {styled} from "@mui/system";
import QuizComponent from "../components/quizComponent";
import {Button, Typography} from "@mui/material";
import {colors} from "../styles/globalStyles";
import {Label} from "../components/modals/rateGrid/rateGrid";
import leftArrow from "./../images/left_arrow.svg";
import {getQuizData, postQuiz} from "../api/quiz";
import QuizPersonalData from "../components/QuizPersonalData";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {userInfoAtomArr} from "../recoil/atoms/userAtom";
import Preloader from "../components/preloader";
import QuizInstructions from "../components/QuizInstructions";
import { Loader } from "../components/loaders/loader";

const PageWrapper = styled('div')({
    background: colors.white,
    padding: '2px 17px 17px',
    marginBottom: 45,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '0 1 auto',
    gap: 17,
    minHeight: '100vh',
    maxWidth: 390,
    margin: "0 auto"
});

const Wrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    transition: 'transform 0.5s ease',
    width: '100%',
    overflow: 'hidden'
});

const ContentWrapper = styled('div')(({currentIndex, quizData}) => ({
    display: 'flex',
    transform: `translateX(-${currentIndex * 100}%)`,
    transition: 'transform 0.5s cubic-bezier(.91,0,.13,.99)',
    width: "100%"
}));

const ButtonsGroup = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    padding: '0 10px'
});

const PrevButton = styled(Button)({
    background: colors.darkBlue,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '18px 8px',
    'img': {
        width: '54px',
        height: '34px'
    },

    '&:disabled': {
        opacity: 0.5
    },
    "&:active": {
        background: colors.darkBlue,
    },
    "&:hover": {
        background: colors.darkBlue,
    }
});

const SaveButton = styled(Button)({
    background: colors.transparentWhite,
    borderRadius: 23,
    color: "white",
    padding: '18px 8px',
    flex: 1,

    "&.light": {
        background: colors.main,
        color: colors.white
    },

    '&:disabled': {
        opacity: 0.5,
    }
});

const ErrorWrapper = styled('div')({
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
    padding: '15px',
    gap: '25px',
    flexDirection: 'column',

    '& button': {
        flex: 0,
        padding: '18px'
    }
});

const ErrorMessage = styled(Typography)({
    lineHeight: 1,
    letterSpacing: "0.01rem",
    fontWeight: 800,
    fontSize: '20px',
    textAlign: 'center',
    color: colors.darkBlue,
    marginLeft: 'auto',
    marginRight: 'auto',
});

const ErrorDetails = styled(Typography)({
    lineHeight: 1,
    letterSpacing: "0.01rem",
    fontWeight: 400,
    fontSize: '16px',
    textAlign: 'center',
    color: colors.darkBlue,
    marginLeft: 'auto',
    marginRight: 'auto',
});

const QuizPage = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [quizData, setQuizData] = useState([]);
    const [name, setName] = useState(window.Telegram?.WebApp?.initDataUnsafe?.user?.first_name ? window.Telegram?.WebApp?.initDataUnsafe?.user?.first_name : '');
    const [surname, setSurname] = useState(window.Telegram?.WebApp?.initDataUnsafe?.user?.last_name ? window.Telegram?.WebApp?.initDataUnsafe?.user?.last_name : '');

    const [isLoading, setIsLoading] = useState(false);

    const [isFetching, setIsFetching] = useState(true);
    const [errorTeamText, setErrorTeamText] = useState(null);

    const totalQuestions = quizData.length + 1;
    const isAnswerSelected = selectedAnswers[quizData[currentIndex - 2]?.id] !== undefined;

    useEffect(() => {
        const teamHash = searchParams.get("team") || window?.Telegram?.WebApp?.initDataUnsafe?.start_param;
        
        getQuizData(teamHash)
            .then((response) => {
                setQuizData(response.data);
            })
            .catch((err) => {
                console.error('Ошибка получения вопросов', err);
                setErrorTeamText(err.response?.data?.message || err.message);
            });
        setIsFetching(false);
    }, []);

    const handlePrevQuestion = () => {
        setCurrentIndex(currentIndex - 1);
    }

    const handleNextQuestion = () => {
        if (currentIndex < totalQuestions) {
            setCurrentIndex(currentIndex + 1);
        } else {
            const teamHash = searchParams.get("team") || window?.Telegram?.WebApp?.initDataUnsafe?.start_param;

            const quizData = {
                team_hash: teamHash,
                survey: selectedAnswers,
                user: {
                    name: name,
                    surname: surname,
                    telegram_id: window.Telegram?.WebApp?.initDataUnsafe?.user?.id
                }
            }

            setIsLoading(true);
            postQuiz(quizData)
                .then((res) => {
                    localStorage.setItem('auth-token', res.data.tokens.access);
                    localStorage.setItem('refresh-token', res.data.tokens.refresh);

                    navigate("/main");
                    setIsLoading(false);

                    // setIsModalOpen(true);
                    // setValues(res.data.value_list);
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.error('Ошибка отправки результатов опроса', err);
                });
        }
    };

    const handleAnswerSelect = (questionId, value, questionType) => {
        if (questionType === "options") {
            setSelectedAnswers((prev) => {
                const newAnswers = { ...prev };

                if (!newAnswers[questionId]) {
                    newAnswers[questionId] = [value];
                    return newAnswers;
                }

                if (newAnswers[questionId].indexOf(value) === -1) {
                    newAnswers[questionId].push(value);

                } else {
                    newAnswers[questionId] = newAnswers[questionId].filter((optionId) => (optionId !== value));
                }

                return newAnswers;
            });

        } else {
            setSelectedAnswers((prev) => ({
                ...prev,
                [questionId]: value
            }));
        }
    };

    const quizItems = quizData.map((question, index) => (
        <QuizComponent
            key={index}
            question={question}
            onAnswerSelect={handleAnswerSelect}
            selectedAnswer={selectedAnswers[question.id]}
        />
    ));

    const handleReturnToAuth = () => {
        window.Telegram.WebApp.initDataUnsafe.start_param = null;
        navigate('/auth');
    };

    if (errorTeamText) {
        return (
            <ErrorWrapper>
                <ErrorMessage>{errorTeamText}</ErrorMessage>
                <ErrorDetails>Обратитесь к лидеру вашей команды для получения корректной ссылки</ErrorDetails>
                <SaveButton onClick={handleReturnToAuth} className={"light"}>Вернуться на страницу входа</SaveButton>
            </ErrorWrapper>
        )
    }

    return (
        <>
            {isFetching && <Preloader/>}
            <PageWrapper>
                <div></div>
                <Wrapper>
                    <ContentWrapper currentIndex={currentIndex} quizData={quizData}>
                        <QuizPersonalData name={name} surname={surname}
                                            onChangeName={(event) => setName(event.target.value)}
                                            onChangeSurname={(event) => setSurname(event.target.value)}/>
                        <QuizInstructions isLeader={searchParams.get("isLeader")} />
                        {quizItems}
                    </ContentWrapper>
                </Wrapper>
                <ButtonsGroup>
                    <PrevButton disabled={!currentIndex} onClick={handlePrevQuestion}>
                        <img src={leftArrow} alt="Назад"/>
                    </PrevButton>
                    <SaveButton
                        className={"light"}
                        id={"save-button"}
                        name={"save-button"}
                        onClick={handleNextQuestion}
                        disabled={(currentIndex !== 0 && currentIndex !== 1 && !isAnswerSelected) || (!(name.trim()) || !(surname.trim()))}
                    >
                        <Label style={{fontSize: 20}}>
                            {isLoading ? (<Loader size="medium" />) : currentIndex < totalQuestions ? "Продолжить" : "Сохранить"}
                        </Label>
                    </SaveButton>
                </ButtonsGroup>
            </PageWrapper>
        </>
    );
};

export default QuizPage;
