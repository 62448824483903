import { Button, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import Bg from '../images/bg.png';
import { colors } from '../styles/globalStyles';
import RootModal from './modals/rootModal';
import StoriesGuide from "./storiesGuide";

const Layout = styled('div')({
    padding: 17,

    background: `url(${Bg}), linear-gradient(27.72deg, #3B4EF6 23.24%, #3BC9F6 100%)`,
    backgroundSize: 'cover',

    borderRadius: 40,
});

const TypographyHeader = styled(Typography)({
    padding: 10,
    paddingBottom: 17,

    fontWeight: 700,
    fontSize: 24,
    lineHeight: '120%',
    color: colors.white,
});

const ButtonsSection = styled('section')({
    paddingBottom: 7,
    marginBottom: '10px',
    marginRight: -17,

    display: 'flex',
    gap: 2,
    overflowX: 'auto',

    '& :last-child': {
        marginRight: 10,
    },
});

const GuideButton = styled(Button)({
    padding: 17,

    width: '100%',
    minWidth: 116,
    height: 143,

    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',

    background: colors.transparentWhite,
    textAlign: 'left',
    borderRadius: 23,

    '&:hover': {
        background: colors.transparentMain,
        border: `1px solid ${colors.transparentWhite}`,
    },
});

const TypographyGuide = styled(Typography)({
    marginBottom: 3,

    fontSize: 10,
    fontWeight: 500,
    lineHeight: '130%',
    opacity: 0.6,
    color: colors.white,
});

const TypographyMain = styled(Typography)({
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '100%',
    color: colors.white,
    textTransform: 'none',
});

export const Header = styled('header')({
    padding: '0 17px 17px 17px',
    marginBottom: 17,

    borderBottom: '0.33px solid rgba(0, 0, 0, 0.3)',
});

export const HeaderTitle = styled(Typography)({
    marginRight: 'auto',
    paddingLeft: 10,

    fontSize: 35,
    fontWeight: 800,
    lineHeight: '100%',

    color: colors.darkBlue,

    '& span': {
        color: colors.main,
    },
});

export const ModalMainContent = styled('div')({
    paddingInline: 27,
});

export const ModalTypography = styled(Typography)({
    marginBottom: 8,

    fontSize: 20,
    fontWeight: 400,

    '& p': {
        marginBottom: 8,
    },
    '& ul, ol': {
        marginLeft: 27,
    },
    '& a': {
        color: colors.main
    }
});

export const ModalImg = styled('img')({
    width: '100%',

    borderRadius: 40,
});

export const ModalCards = styled('div')({
    position: 'fixed',
    bottom: 0,

    width: '100%',
    maxWidth: 390,
    background: 'inherit',
});

export const ModalCard = styled(Typography)({
    padding: '20px 0 10px 37px',

    borderColor: 'rgba(0, 0, 0, 0.3)',
    borderWidth: '0.33px 0.33px 0px 0.33px',
    borderStyle: 'solid',
    borderRadius: '40px 40px 0px 0px',

    fontSize: 15,
    fontWeight: 500,
    color: colors.main,

    cursor: 'pointer',

    '&:hover': {
        background: 'rgba(0, 0, 0, 0.05)',
    },
});

const SupportCenterInstruction = ({ data }) => {
    const modalCardsHeight = document.getElementById('modal-cards')?.offsetHeight ?? 177;

    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState(data[0]);

    const handleGuideButtonClick = (i) => {
        setModalData(data[i]);
        setOpen(true);
    };

    const isTelegramUser = window.Telegram?.WebApp?.initDataUnsafe?.user?.id;

    return (
        <Layout>
            <TypographyHeader>Инструкции</TypographyHeader>
            {isTelegramUser && <StoriesGuide />}
            <ButtonsSection>
                {data.map((item, i) => (
                    <GuideButton key={i} onClick={() => handleGuideButtonClick(i)}>
                        <TypographyGuide>Гайд</TypographyGuide>
                        <TypographyMain>{item.titleText}</TypographyMain>
                    </GuideButton>
                ))}
            </ButtonsSection>
            <RootModal
                open={open}
                onClose={() => setOpen(false)}
                breadcrumbsData={[
                    {
                        label: 'Центр Поддержки',
                        uri: '/support',
                    },
                    {
                        label: 'Инструкция',
                        uri: '',
                    },
                ]}
                style={{ display: 'block' }}
            >
                <Header>
                    <HeaderTitle dangerouslySetInnerHTML={{ __html: modalData.title }} />
                </Header>
                <ModalMainContent sx={{ marginBottom: `${modalCardsHeight + 17}px` }}>
                    <ModalTypography
                        component="div"
                        dangerouslySetInnerHTML={{ __html: modalData.text }}
                    />
                </ModalMainContent>
                <ModalCards id="modal-cards">
                    {data
                        .filter(({ titleText }) => titleText !== modalData.titleText)
                        .slice(0, 3)
                        .map((card) => (
                            <ModalCard key={card.titleText} onClick={() => setModalData(card)}>
                                {card.titleText}
                            </ModalCard>
                        ))}
                </ModalCards>
            </RootModal>
        </Layout>
    );
};

export default SupportCenterInstruction;
