import {styled} from "@mui/system";
import storiesData from "./../data/stories.json";
import StoryModal from "./modals/storyModal";

const Wrapper = styled('div')({
    overflowX: "auto",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    paddingBottom: '7px',
    marginBottom: '10px',
    marginRight: '-16px'

    // scrollbarWidth: "none",
    // "&::-webkit-scrollbar": {
    //     display: "none",
    // }
});

const StoriesGuide = () => {
    const storiesGroupsItems = storiesData.map(storiesGroup => <StoryModal key={storiesGroup.id} storiesGroup={storiesGroup} allStoriesList={storiesData} />)

    return (
        <Wrapper>
            {storiesGroupsItems}
        </Wrapper>
    )
}

export default StoriesGuide;