import {axiosInstance} from "./axiosInstance";
import axios from "axios";
import {SERVER_URI_API} from "./urls";

export const getWatchedStoriesData = (userId) => axios.get(`${SERVER_URI_API}/stories/watched/?user_id=${userId}`);

export const putWatchedSet = (data) => axios.put(`${SERVER_URI_API}/stories/watched/`, data,
    {
        headers: {
            'Content-Type': 'application/json',
        }
    }
);