import React, {useEffect, useState} from 'react';
import {Button, Chip, Link, styled, Typography} from '@mui/material';
import {colors} from '../styles/globalStyles';
import Layout from '../components/Layout';
import StarOrMessageChip from '../components/chips/starOrMessageChip';
import ProgressComponent from '../components/progressComponent';
import ProgressTracker from '../components/progressTracker';
import {ReactComponent as ColoredDot} from '../images/colored_dot.svg';
import {ReactComponent as ArrowRight} from '../images/arrow_right.svg';
import Bg from '../images/bg.png';

import {
    kssDataAtom,
    userForAdminPanelAtom,
    userInfoAtomArr,
    userTrackerForAdminPanelAtom
} from '../recoil/atoms/userAtom';
import {useRecoilState, useRecoilValue} from 'recoil';
import {useNavigate} from 'react-router-dom';
import {getUserData} from '../api/auth';
import {getAvatar} from '../api/user';
import {getMyProgress} from '../api/stats';
import {getAllTimeAvgFeedbackRates} from '../api/score';
import {dateFormatDiff, timeFormatDiff} from '../functions/nextSessionFormat';
import {
    avgBehaviorsRateAtom,
    progressDataAtom,
    timeDateAtom,
    timeHoursAtom,
} from '../recoil/atoms/mainPanelPageAtoms';
import PatternedButton from "../components/inputs/patternedButton";
import {AdminUserAtom} from '../recoil/atoms/adminPanelAtoms';
import StoryModal from "../components/modals/storyModal";
import stories from "../data/stories.json";
import {getWatchedStoriesData} from "../api/stories";


const FeedbackAndSessions = styled('div')({
    display: 'flex',
    gap: 2,

    letterSpacing: '0.01em',
});

const Feedback = styled('section')({
    padding: 17,
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',

    background: colors.transparentMain,
    borderRadius: 40,
});

const TypographyHeader = styled(Typography)({
    paddingInline: 10,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '120%',
    color: colors.darkBlue,
});

const BehaviorNameBox = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 5,
    marginTop: 17,
    marginBottom: 4,
    marginLeft: 7,
});

const TypographyAboveChip = styled(Typography)({
    display: 'flex',
    alignItems: 'center',

    fontSize: 12,
    fontWeight: 500,
    lineHeight: '125%',
    color: colors.main,

    '& svg': {
        marginRight: 5,
    },
    '&.sessions': {
        display: 'block',
        marginBottom: 10,
        paddingInline: 10,
        fontSize: 14,
        lineHeight: '100%',
        color: colors.white,
        opacity: 0.6,
    },
});

const Sessions = styled('section')({
    padding: 17,
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    background: `url(${Bg}), radial-gradient(140.07% 100% at 0% 100%, #9C17CB 53.17%, #CB1799 100%)`,
    backgroundSize: 'cover',
    borderRadius: 40,
});

const StyledChip = styled(Chip)({
    marginBottom: 17,
    padding: 17,

    width: '100%',
    height: 87,
    display: 'block',

    background: colors.transparentWhite,
    borderRadius: 23,

    '& span': {
        padding: 0,
    },
});

const Time = styled(Typography)({
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '110%',
    color: colors.white,
});

const DateTypography = styled(Typography)({
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '110%',
    color: colors.white,
});

const StyledButton = styled(Button)({
    width: '100%',
    height: 52,

    background: colors.white,
    backgroundSize: 'cover',
    borderRadius: 23,

    fontSize: 12,
    fontWeight: 700,
    lineHeight: '100%',
    color: '#BD17CB',

    '& span': {
        marginLeft: 17,
    },
    '&:hover': {
        backgroundColor: colors.white,
    },
});

const MainPanelWrap = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 17,
    gap: 17,
});

const TopBar = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const Greeting = styled(Typography)({
    fontSize: 32,
    lineHeight: '105%',
    letterSpacing: '0.01em',
    color: colors.darkBlue,
    marginLeft: 17,

    '& .accent': {
        color: colors.main,
    },
});

const MainPanel = () => {
    const [userInfo, setUserInfo] = useRecoilState(userInfoAtomArr);
    const [progressData, setProgressData] = useRecoilState(progressDataAtom);
    const [timeHours, setTimeHours] = useRecoilState(timeHoursAtom);
    const [timeDate, setTimeDate] = useRecoilState(timeDateAtom);
    const [avgBehaviorsRate, setAvgBehaviorsRate] = useRecoilState(avgBehaviorsRateAtom);

    const [adminUser, setAdminUser] = useRecoilState(AdminUserAtom);

    const userKssData = useRecoilValue(kssDataAtom);
    // const userTrackerData = useRecoilValue(kssTrackerDataAtom);
    const userTrackerForAdminPanel = useRecoilValue(userTrackerForAdminPanelAtom);
    const userForAdminPanel = useRecoilValue(userForAdminPanelAtom);

    const kssTrackerData = (adminUser.appAdminPanel ? userTrackerForAdminPanel : userKssData);

    const [hasWatchedStories, setHasWatchedStories] = useState(true);
    const storySetId = 2;


    let navigate = useNavigate();


    function handleAdminPanelButtonClick() {
        localStorage.removeItem('user-id-for-admin-panel');
        navigate(`/admin/company/${userForAdminPanel.companyId}/team/${userForAdminPanel.teams[0].id}/users`);
    }

    useEffect(() => {
        if (adminUser.appAdminPanel && !userForAdminPanel.id) {
            navigate("/admin");
        }

        getUserData(userForAdminPanel.id)
            .then(({data}) => {
                if (data.is_staff || data.app_admin_panel === true) {
                    setAdminUser({
                        isStaff: data.is_staff,
                        appAdminPanel: data.app_admin_panel,
                    });

                    if (!userForAdminPanel.id) {
                        navigate("/admin");
                    }
                }
                var userData = {
                    username: data.username,
                    firstName: data.first_name,
                    userId: data.id,
                    teamName: data.team.name,
                    teamId: data.team.id,
                    isStaff: data["is_staff"],
                    isSuperuser: data["is_superuser"],
                };
                setTimeHours(timeFormatDiff(data.session?.next_date, data.session?.meeting_date))
                setTimeDate(dateFormatDiff(data.session?.next_date, data.session?.meeting_date))


                if (!userInfo.avatar) {
                    getAvatar().then(({data}) => {
                        const avatar = `https://app.intg101.work${data}`;
                        localStorage.setItem('avatar', avatar);
                        userData.avatar = avatar
                        setUserInfo((prev) => (userData));
                    });
                }

                setUserInfo((prev) => ({...prev, ...userData}));

                if (userForAdminPanel.id) {
                    getMyProgress(userForAdminPanel.id)
                        .then(({data}) => {
                            setProgressData(data)
                        })
                        .catch((err) => {
                            console.error("Ошибка получения прогресса", err);
                        });

                    getAllTimeAvgFeedbackRates(userForAdminPanel.id)
                        .then(({data}) => {
                            setAvgBehaviorsRate(data)
                        })
                        .catch((err) => {
                            console.error("Ошибка получения средних оценок", err);
                        });

                } else {
                    getMyProgress()
                        .then(({data}) => {
                            setProgressData(data)
                        })
                        .catch((err) => {
                            console.error("Ошибка получения прогресса", err);
                        });

                    getAllTimeAvgFeedbackRates(userInfo.userId)
                        .then(({data}) => {
                            setAvgBehaviorsRate(data)
                        })
                        .catch((err) => {
                            console.error("Ошибка получения средних оценок", err);
                        });
                }

                if (!window.Telegram?.WebApp?.initDataUnsafe?.user?.id) {
                    return;
                }

                getWatchedStoriesData(window.Telegram?.WebApp?.initDataUnsafe?.user?.id)
                    .then((res) => {
                        const watchedSets = res.data?.watched_story_sets;
                        const isWatched = watchedSets.includes(storySetId);

                        if (isWatched) {
                            return;
                        }

                        setHasWatchedStories(false);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            })
            .catch((error) => {
                console.error("Ошибка получения пользователя", error);
            });

    }, []);

    return (
        <Layout>
            {!hasWatchedStories &&
                <StoryModal storiesGroup={stories.find(group => group.id === storySetId)} isAutoOpen={true}/>}
            <MainPanelWrap>
                <TopBar>
                    <Chip label={'Главная'} variant={'lightBlue'} sx={{height: 20}}/>
                    <Link
                        sx={{color: colors.main, cursor: 'pointer'}}
                        onClick={() => navigate('/support')}
                    >
                        Как тут все устроено?
                    </Link>
                </TopBar>
                {(userInfo.isSuperuser || adminUser.appAdminPanel) ?
                    <PatternedButton label={"Админ панель"} onClick={handleAdminPanelButtonClick}/>
                    : null
                }
                {userForAdminPanel.first_name ? (
                    <Greeting variant={'h2'}>
                        <span className={'accent'}>Привет, </span> {userForAdminPanel.first_name}
                    </Greeting>

                ) : (
                    <Greeting variant={'h2'}>
                        <span className={'accent'}>Привет,</span> {userInfo.firstName}
                    </Greeting>

                )}
                <ProgressComponent
                    percentage={progressData.progress}
                    period={progressData.period}
                />
                <ProgressTracker mainPanel hasKssData={kssTrackerData.length > 0}/>
                <FeedbackAndSessions>
                    <Feedback>
                        <TypographyHeader>Фидбэк</TypographyHeader>
                        <div style={{width: '100%'}}>
                            <BehaviorNameBox>
                                <ColoredDot
                                    style={{minWidth: 10, minHeight: 10}}
                                    fill={colors.green}
                                />
                                <TypographyAboveChip>Положительные</TypographyAboveChip>
                            </BehaviorNameBox>
                            <div style={{height: '64px', width: '100%'}}>
                                <StarOrMessageChip
                                    number={avgBehaviorsRate?.positive ?? '-'}
                                    radius={23}
                                    height={64}
                                    background={colors.transparentMain}
                                    stars={avgBehaviorsRate?.positive ?? -1}
                                />
                            </div>
                        </div>
                        <div style={{width: '100%'}}>
                            <BehaviorNameBox>
                                <ColoredDot
                                    style={{minWidth: 10, minHeight: 10}}
                                    fill={colors.red}
                                />
                                <TypographyAboveChip>Отрицательные</TypographyAboveChip>
                            </BehaviorNameBox>
                            <div style={{height: '64px', width: '100%'}}>
                                <StarOrMessageChip
                                    number={avgBehaviorsRate?.negative ?? '-'}
                                    radius={23}
                                    height={64}
                                    background={colors.transparentMain}
                                    stars={avgBehaviorsRate?.negative ?? -1}
                                />
                            </div>
                        </div>
                    </Feedback>
                    <Sessions>
                        <TypographyHeader
                            sx={{color: colors.white}}>{timeHours ? "Сессии" : "Сессия не задана"}</TypographyHeader>
                        {timeHours && (
                            <>
                                <div>
                                    <TypographyAboveChip className="sessions">
                                        Следующая:
                                    </TypographyAboveChip>
                                    <StyledChip
                                        label={
                                            <>
                                                <Time>{timeHours}</Time>
                                                <DateTypography>{timeDate}</DateTypography>
                                            </>
                                        }
                                    />
                                </div>
                                <StyledButton
                                    onClick={() => navigate('/my-sessions')}
                                    endIcon={<ArrowRight fill="#BD17CB"/>}
                                >
                                    Подробнее
                                </StyledButton>
                            </>
                        )}
                    </Sessions>
                </FeedbackAndSessions>
                <PatternedButton label={"Инструкция"} onClick={() => navigate('/support')}/>
            </MainPanelWrap>
        </Layout>
    );
};

export default MainPanel;
