import React, {useEffect, useState} from "react";
import Layout from "../components/Layout";
import {styled} from "@mui/material";
import {colors} from "../styles/globalStyles";
import {useParams} from "react-router-dom";
import {getTeamSurveyResults} from "../api/adminPanel";
import { Box } from "@mui/system";


const QuestionCard = styled("div")({
    padding: '10px 10px 20px',
    borderRadius: 23,
    backgroundColor: colors.transparentMain,
    display: "flex",
    flexDirection: "column",
    gap: 5,
})

const Title = styled("div")({
    lineHeight: 1.5,
    letterSpacing: "0.01rem",
    fontWeight: 700,
    fontSize: 14,
    fontFamily: "Onest",
    color: colors.darkBlue,
    padding: "10px 7px"
});

const AnswerList = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: '10px'
});

const AnswerSliderList = styled('div')({
    display: "flex",
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '10px'
});

const AnswerItem = styled("div")({
    padding: 20,
    borderRadius: 23,
    backgroundColor: colors.white,
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-between',
    gap: '10px',
    overflowX: 'hidden'
});

const AnswerText = styled("div")({
    lineHeight: 1,
    letterSpacing: "0.01rem",
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "Onest",
    color: colors.darkBlue,
});

const AnswerCounter = styled(AnswerText)({
    color: colors.main,
    fontWeight: 700
});

export const BarBackground = styled(Box)(({ count, maxAnswerCount }) => {
    const barHeight = maxAnswerCount === 0 ? 14 : count / maxAnswerCount * 76 + 14;
    const barRounding = count > 0 ? 4 : 0;

    return {
        width: '100%',
        height: `${barHeight}%`, 
        borderRadius: `${barRounding}px ${barRounding}px 8px 8px`,
    
        position: 'absolute',
        bottom: 0,
    
        backgroundColor: colors.green,
        opacity: .1,
    };
});

const AnswerSliderWrapper = styled('div')({
    borderRadius: 8,
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'column',
    minWidth: '40px',
    minHeight: '40px',
    textTransform: 'uppercase',
    textAlign: 'center',
    paddingBottom: '10px',
    position: 'relative',
});

const TeamSurveyResultsPage = () => {

    const {teamId, companyId} = useParams();
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        getTeamSurveyResults(teamId)
            .then(res => {
                setQuestions(res.data.questions);
            })
            .catch((err) => {
                console.error("Ошибка получения ценностей команды", err);
            });
    }, []);

    const questionItems = questions?.map(question => <Question key={question.id} question={question}/>)


    return (
        <Layout link={`/admin/company/${companyId}/team/${teamId}`}>
            {questions && questionItems}
        </Layout>
    )
};

const Question = ({question}) => {
    const questionType = question.type;
    let answers;

    switch (questionType) {
        case 'number':
            const answerNumberItems = [];
            let maxAnswerCount = 0;

            for (let answer in question.answers) {
                if (Number.isInteger(+answer) && question.answers[answer] > maxAnswerCount) {
                    maxAnswerCount = question.answers[answer];
                }
            }

            for (let answer in question.answers) {
                answerNumberItems.push(<SliderAnswer answer={answer} counter={question.answers[answer]} maxAnswerCount={maxAnswerCount} />)
            }

            answers = <AnswerSliderList>{answerNumberItems}</AnswerSliderList>;
            break;
        case 'options':
            const answerOptionsItems = question.answers?.map(answer => <Answer key={answer.id} answer={answer} />);
            answers = <AnswerList>{answerOptionsItems}</AnswerList>
            break;
        default:
            if (question.answers?.length > 0) {
                const answerTextItems = question.answers?.map((answer, index) => <AnswerItem
                    key={index}><AnswerText>{answer}</AnswerText></AnswerItem>);
                answers = <AnswerList>{answerTextItems}</AnswerList>
                break;
            }
            answers = <AnswerText sx={{textAlign: 'center'}}>Пока никто не ответил</AnswerText>
    }

    return (
        <QuestionCard>
            <Title>{question.title}</Title>
            {answers}
        </QuestionCard>
    )
};

const Answer = ({ answer }) => {
    return (
        <AnswerItem>
            <AnswerText>
                {answer.title}
            </AnswerText>
            <AnswerCounter>
                {answer.count}
            </AnswerCounter>
        </AnswerItem>
    )
};

const SliderAnswer = ({ answer, counter, maxAnswerCount }) => {
    return (
        <AnswerSliderWrapper>
            {Number.isInteger(+answer) && (
                <BarBackground count={counter} maxAnswerCount={maxAnswerCount} />
            )}
            <Title>
                {answer}
            </Title>
            <AnswerCounter>
                {counter}
            </AnswerCounter>
        </AnswerSliderWrapper>
    )
};

export default TeamSurveyResultsPage;