import {Chip, IconButton, styled, Typography} from "@mui/material";
import {colors} from "../styles/globalStyles";
import Bg from "../images/bg.png";
import React, {useState} from "react";
import {ReactComponent as Tick} from "../images/tick.svg";

const ValueCard = styled("div")({
    padding: '10px 15px',
    borderRadius: 23,
    backgroundColor: colors.transparentMain,
    display: "flex",
    flexDirection: "column",
    gap: "10px"
});

const Title = styled(Typography)({
    fontSize: '20px'
});

const ListItem = styled('div')(({color}) => ({
    cursor: 'pointer',
    marginBottom: 4,
    padding: 17,
    minHeight: 72,

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    backgroundColor: colors.white,
    borderRadius: 23,

    '&.active': {
        background: `url(${Bg}), linear-gradient(267.39deg, #FFD952 -8.46%, #11D1A3 39.05%, #1198D1 92.96%)`,
        backgroundBlendMode: 'multiply',
        '& p': {
            color: colors.white,
        },
    },
}));

const TypographyListItem = styled(Typography)({
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '128%',
});

const TickButton = styled(IconButton)({
    marginLeft: 10,
    width: 48,
    height: 48,
    background: colors.white,
    border: '2px solid rgba(59, 122, 246, 0.14)',
    borderRadius: 13,

    '&.active': {
        background: 'rgba(255, 255, 255, 0.14)',
        border: '2px solid rgba(59, 122, 246, 0.28)',
    },
});

const ResultsValue = ({value, handleChange, behaviorsData}) => {

    const behaviorsItems = value.behavior?.map(behavior => <Behavior behaviorsData={behaviorsData}
                                                                     handleChange={handleChange} key={behavior.id}
                                                                     behavior={behavior}/>);

    return (
        <ValueCard name="value">
            <Title>
                {value.name}
            </Title>
            {behaviorsItems}
        </ValueCard>
    )
};

export default ResultsValue;

const Behavior = ({behavior, handleChange, behaviorsData}) => {
    const isActive = behaviorsData[behavior.id];

    const handleClick = () => {
        handleChange(behavior.id);
    }

    const pluralizePeople = (n) => {
        const lastDigit = n % 10;
        const lastTwoDigits = n % 100;

        const verb = (lastDigit === 1 && lastTwoDigits !== 11) ? 'Выбрал' : 'Выбрали';

        let word;
        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
            word = 'человек';
        } else if (lastDigit === 1) {
            word = 'человек';
        } else if (lastDigit >= 2 && lastDigit <= 4) {
            word = 'человека';
        } else {
            word = 'человек';
        }

        return `${verb} ${n} ${word}`;
    };

    return (
        <ListItem name="behavior" onClick={handleClick} className={isActive ? 'active' : null}>
            <div>
                <TypographyListItem>
                    {behavior.title}
                </TypographyListItem>
                {(behavior.count || behavior.count === 0) && <TypographyListItem>{pluralizePeople(behavior.count)}</TypographyListItem>}
            </div>
            <TickButton
                className={isActive ? 'active' : null}
            >
                {isActive && <Tick/>}
            </TickButton>
        </ListItem>
    )
}