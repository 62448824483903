import { Box } from "@mui/system";

export function Loader({ size='small' }) {
   const sizes = {
      small: 16,
      medium: 24,
      large: 32,
   };

   const widths = {
      small: 2,
      medium: 3,
      large: 4,
   };

   return (
      <Box sx={{
         height: sizes[size] || sizes.small,
         width: sizes[size] || sizes.small,
         borderRadius: (sizes[size] || sizes.small) / 2,
         border: `${widths[size] || widths.small}px solid white`,
         borderBottomColor: 'transparent',
         animation: 'spin 1s linear infinite', // Добавить анимацию вращения
         '@keyframes spin': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' },
         },

      }} />
   )
};