import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Layout from "../components/Layout";
import {styled} from "@mui/system";
import {Breadcrumbs, Link, Popover, Typography} from "@mui/material";
import {colors} from "../styles/globalStyles";
import { ReactComponent as DotDivider } from '../images/dot_divider.svg';
import PatternedButton from "../components/inputs/patternedButton";
import SessionCreateModal from "../components/pages/adminPanel/sessionCreateModal";
import {getExcel, getTeamInfo} from "../api/adminPanel";
import {getUserData} from "../api/auth";

const StyledBreadcrumbs = styled(Breadcrumbs)({
    "& a.light": {
        opacity: 0.4,
        color: colors.darkBlue,
    },

    "& a.transparent": {
        opacity: 0.4,
        color: colors.white
    },

    "& a.current.light": {
        opacity: 1,
        color: colors.main,
    },

    "& a.current.transparent": {
        opacity: 1,
        color: colors.white
    }

})

const ButtonsWrap = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 17,
})

const TeamControlPage = ()=>{
    const [sessionCreateModal, setSessionCreateModal] = useState(false);
    const [sharingUrl, setSharingUrl] = useState("");
    const {teamId, companyId} = useParams()
    const [loading, setLoading] = useState(false)
    const [breadcrumbsData, setBreadcrumbsData] = useState({});
    const navigate = useNavigate()

    useEffect(() => {
        getTeamInfo(teamId)
            .then(r=>{
                setBreadcrumbsData(r.data)

                setSharingUrl(r.data?.team?.sharing_url);
            })
            .catch((err) => {
                console.error("Ошибка получения информации о команде", err);
            });
    }, []);

    const handleExcelButtonClick = (e) => {
        setLoading(true)
        e.preventDefault()
        getExcel(teamId)
            .then(res => {
                // create file link in browser's memory
                const href = URL.createObjectURL(res.data);
                const fileName = res.headers['content-disposition'].split("=")[1].replaceAll('"', '')

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                setLoading(false)
            })
            .catch((err) => {
                console.error("Ошибка получения excel файла", err);
            });
    }

    const breadcrumbs=[
        {
            label: breadcrumbsData?.team?.name,
        },
        {
            label: "Новая сессия",
        }
    ];

    //Popover MUI
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickAnchor = (event) => {
        copyToClipboard();

        // setAnchorEl(event.currentTarget);
    };

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    //
    // const openPopover = Boolean(anchorEl);
    // const popoverId = openPopover ? 'simple-popover' : undefined;

    //Копирование в буфер обмена
    const copyToClipboard = async () => {
        if (!navigator.clipboard) {
            console.error('Clipboard API не поддерживается в этом браузере');
            return;
        }

        try {
            await navigator.clipboard.writeText(sharingUrl);
        } catch (err) {
            console.error('Ошибка копирования: ', err);
        }
    };

    return (<Layout link={"/admin"}>
        <StyledBreadcrumbs separator={<DotDivider style={{ width: 2, height: 2 }}/>}>
            <Link className="light" onClick={() => navigate('/admin')}>
                {breadcrumbsData?.company?.name}
            </Link>
            <Link>
                {breadcrumbsData?.team?.name}
            </Link>
            <Link className="current">Профиль</Link>
        </StyledBreadcrumbs>
        <ButtonsWrap>
            {window.Telegram?.WebApp?.initDataUnsafe?.user && (
                <PatternedButton disabled={sharingUrl.trim().length === 0} endIcon={null} label={"Скопировать приглашение"} onClick={handleClickAnchor}/>
            )}
            {/*<PatternedButton endIcon={null} aria-describedby={popoverId} label={"Скопировать приглашение"} onClick={handleClickAnchor}/>*/}
            {/*<Popover*/}
            {/*    id={popoverId}*/}
            {/*    open={openPopover}*/}
            {/*    anchorEl={anchorEl}*/}
            {/*    onClose={handleClose}*/}
            {/*    anchorOrigin={{*/}
            {/*        vertical: 'bottom',*/}
            {/*        horizontal: 'center',*/}
            {/*    }}*/}
            {/*    transformOrigin={{*/}
            {/*        vertical: 'top',*/}
            {/*        horizontal: 'center',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Typography sx={{ p: 2 }}>{sharingUrl}</Typography>*/}
            {/*</Popover>*/}
            <PatternedButton label={"Задать сессию"} onClick={()=>setSessionCreateModal(true)}/>
            <PatternedButton label={"Актуальные и прошлые сессии"} onClick={()=>navigate(`/admin/company/${companyId}/team/${teamId}/session-history`)}/>
            <PatternedButton label={"Пользователи"} onClick={()=>navigate(`/admin/company/${companyId}/team/${teamId}/users`)}/>
            <PatternedButton label={"Ценности и Способы поведения "} onClick={()=> navigate((`/admin/company/${companyId}/team/${teamId}/behaviors`))}/>
                
            {window.Telegram?.WebApp?.initDataUnsafe?.user && (
                <PatternedButton label={"Ответы на опрос"} onClick={() => navigate(`/admin/company/${companyId}/team/${teamId}/survey-results`)} />
            )}
            
            <PatternedButton label={"Шкала оценки "} onClick={()=> navigate(`/admin/company/${companyId}/team/${teamId}/max-score`)}/>
            <PatternedButton label={loading ? "Идет загрузка..." : " Выгрузить результаты в Excel"} onClick={handleExcelButtonClick} disabled={loading}/>
        </ButtonsWrap>
        <SessionCreateModal open={sessionCreateModal} teamId={teamId} breadcrumbsData={breadcrumbs} onClose={()=>setSessionCreateModal(false)}/>
    </Layout>)
}

export default TeamControlPage