import {styled} from "@mui/system";
import {Typography} from "@mui/material";
import QuizAnswer from "./quizAnswer";
import QuestionAnswerSlider from "./quizAnswerSlider";
import QuestionAnswerTextarea from "./quizAnswerTextarea";

const Wrapper = styled('div')({
    flex: '0 0 100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    alignItems: 'center',
    minHeight: '300px',
    width: '100%'
});

const QuestionBlock = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
});

const Question = styled(Typography)({
    fontSize: '18px',
    textAlign: 'center'
})

const QuestionSubtitle = styled(Typography)({
    fontWeight: 400,
    fontSize: '14px',
    textAlign: 'center',
});

const AnswersWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
    maxHeight: '50vh',
    overflowY: "auto",
    width: '100%',
    padding: '0 10px',
});

const QuizComponent = ({question, onAnswerSelect, selectedAnswer}) => {
    let answers;

    switch (question.question_type) {
        case 'number':
            answers = <QuestionAnswerSlider question={question} onAnswerSelect={onAnswerSelect}/>;
            break;
        case 'options':
            answers = <AnswersWrapper>{question.options.map(answer => (
                <QuizAnswer
                    key={answer.id}
                    question={question}
                    answer={answer} 
                    isSelected={typeof selectedAnswer === 'object' && (selectedAnswer?.indexOf(answer.id) !== -1)}
                    onAnswerSelect={onAnswerSelect}
                />
            ))}</AnswersWrapper>
            break;
        default:
            answers = <QuestionAnswerTextarea question={question} onAnswerSelect={onAnswerSelect}/>;
    }

    return (
        <Wrapper name={"quiz-block"}>
            <QuestionBlock>
                <Question variant={'h1'}>
                    {question.question_value}
                </Question>
                {question.question_type === 'text' &&
                    <QuestionSubtitle>
                        Напишите все, что приходит в голову. <strong>Не меньше 2 пунктов, отделённых запятой</strong>.
                    </QuestionSubtitle>
                }
            </QuestionBlock>
            {answers}
        </Wrapper>
    );
};

export default QuizComponent;
