import {styled} from "@mui/system";
import {colors} from "../styles/globalStyles";
import {useCallback, useMemo, useState} from "react";
import {debounce} from "../utils";
import {TextField, Typography} from "@mui/material";

const TextareaWrapper = styled('div')({
    width: '100%',
    position: 'relative',
    textarea: {
        fontSize: '16px'
    },

    '.MuiInputBase-root': {
        paddingBottom: '24px'
    },

    '.MuiFormControl-root': {
        width: '100%'
    },

    // '.Mui-focused': {
    //     fieldset: {
    //         borderColor: `${colors.main}`,
    //     },
    // }
});

const Counter = styled(Typography)({
    position: 'absolute',
    fontSize: '14px',
    lineHeight: '14px',
    bottom: '14px',
    right: '14px'
});

const QuestionAnswerTextarea = ({question, onAnswerSelect}) => {
    const [currentLength, setCurrentLength] = useState(0);
    const [isValid, setIsValid] = useState(true);
    const maxlength = 256;
    const id = question.id;

    const debouncedOnAnswerSelect = useMemo(() =>
        debounce(onAnswerSelect, 200), [onAnswerSelect]
    );

    const checkIsValid = (value) => {
        if (!value) {
            return;
        }
        const length = value.split(',').filter(str => str.trim() !== "").length;
        const isValidValue = !!(length && length >= 2);

        setIsValid(isValidValue);
        return isValidValue;
    };

    const handleChangeValue = useCallback((event) => {
        let value = event.target.value.trim() ? event.target.value : undefined;

        setCurrentLength(event.target.value?.length);
        const isValidValue = checkIsValid(value);

        if (!isValidValue) {
            value = undefined
        }
        debouncedOnAnswerSelect(id, value, question.question_type);
    }, [debouncedOnAnswerSelect, id]);

    return (
        <TextareaWrapper>
            <TextField
                error={!isValid}
                // label={!isValid && 'Ошибка'}
                multiline
                inputProps={{maxlength}}
                rows={10}
                onChange={handleChangeValue}
                // helperText="Incorrect entry."
            />
            <Counter>{currentLength}/{maxlength}</Counter>
        </TextareaWrapper>
    )
};

export default QuestionAnswerTextarea;