import preloader from "./../images/preloader.svg";
import {styled} from "@mui/system";

const Wrapper = styled('div')({
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000
});

const Preloader = () => {
    return (
        <Wrapper>
            <img src={preloader} alt="Загрузка"/>
        </Wrapper>
    )
};

export default Preloader;