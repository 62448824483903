import { Box, styled } from '@mui/system';
import { List, ListItem, Typography } from '@mui/material';
import React from 'react';

const Wrapper = styled('div')({
    flex: '0 0 100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '50px',
    alignItems: 'center',
    minHeight: '300px',
    width: '100%',
    maxWidth: '100%',
    maxHeight: 'calc(100vh - 200px)',

    overflowY: 'scroll',
});

const Title = styled(Typography)({
    fontSize: '30px',
});

export const Caption = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    opacity: 0.8,
    lineHeight: 1.5,
    padding: '0 10px',
}));

export const AccentCaption = styled(Caption)(({ theme }) => ({
    backgroundColor: '#eee',
    borderRadius: 10,
    padding: '12px 20px',
    opacity: 1,
}));

export const StepTitleBlock = styled(Typography)(({ theme }) => ({
   display: 'flex',
   alignItems: 'center',
   gap: 12,
}));

export const StepTitle = styled(Typography)(({ theme }) => ({
   lineHeight: 1.2,
}));

export const StepBlock = styled(Box)(({ theme }) => ({
   display: 'flex',
   flexDirection: 'column',
   gap: 32,
}));

export const StepInnerBlock = styled(Box)(({ theme }) => ({
   display: 'flex',
   flexDirection: 'column',
   gap: 12,
}));

export const Coin = styled(Box)(({ theme }) => ({
    minWidth: 28,
    width: 28,
    height: 28,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: 'white',
    fontSize: 14,
    lineHeight: 1,

    backgroundColor: '#3B7AF6',
    borderRadius: 16,
}));

const CustomListItem = ({ children }) => {
    return (
        <ListItem
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
            }}
        >
            <Box
                sx={{
                    minWidth: 12,
                    width: 12,
                    height: 12,
                    borderRadius: 10,
                    border: '2px solid #3B7AF6',
                    backgroundColor: 'rgb(238, 242, 252)',
                }}
            />
            {children}
        </ListItem>
    );
};

const QuizInstructions = ({ isLeader }) => {
    if (!isLeader) {
        return (
            <Wrapper>
                <Title variant={'h1'}>Привет!</Title>
                <Caption>
                    Твой руководитель попросил тебя пройти небольшой опрос, который поможет нашей
                    команде стать ещё эффективнее и создать классную рабочую атмосферу.
                </Caption>

                <AccentCaption>
                    Удели, пожалуйста, пару минут, чтобы ответить&nbsp;на вопросы.
                </AccentCaption>

                <StepInnerBlock>
                    <Caption sx={{ textAlign: 'left' }}>В этом опросе мы хотим узнать:</Caption>
                    <List>
                        <CustomListItem>
                            <Caption sx={{ textAlign: 'left' }}>
                                Какие сильные стороны есть у нашей команды.
                            </Caption>
                        </CustomListItem>
                        <CustomListItem>
                            <Caption sx={{ textAlign: 'left' }}>
                                В чём вы можете стать лучше.
                            </Caption>
                        </CustomListItem>
                        <CustomListItem>
                            <Caption sx={{ textAlign: 'left' }}>
                                Что мешает вам работать ещё эффективнее и чувствовать себя
                                комфортно.
                            </Caption>
                        </CustomListItem>
                    </List>
                </StepInnerBlock>

                <StepInnerBlock>
                    <Caption>
                        Отвечай честно и подробно. Твои ответы будут анонимными и останутся между
                        нами. Твой вклад действительно важен для всей команды!
                    </Caption>

                    <Caption>
                        После того как все пройдут опрос, мы вместе обсудим результаты и выберем
                        лучшие способы улучшить нашу работу.
                    </Caption>

                    <Caption>Спасибо за участие! 😊</Caption>
                </StepInnerBlock>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Title variant={'h1'}>Привет!</Title>
            <Caption>
                Итак, теперь мы попросим вас заполнить опросник, который поможет вам выбрать способы поведения – привычки, над которыми будет полезно работать вашей команде.
            </Caption>

            <StepBlock>
                <StepTitleBlock><Coin>1</Coin><StepTitle>Сначала пройдите опросник самостоятельно</StepTitle></StepTitleBlock>

                <StepInnerBlock>
                    <List>
                        <Caption sx={{ textAlign: 'left' }}>Отвечайте на вопросы честно и подробно. Опросник включает вопросы о:</Caption>
                        <CustomListItem>
                            <Caption sx={{ textAlign: 'left' }}>
                                Сильных сторонах вашей команды.
                            </Caption>
                        </CustomListItem>
                        <CustomListItem>
                            <Caption sx={{ textAlign: 'left' }}>
                                Зонах роста и возможностях улучшения.
                            </Caption>
                        </CustomListItem>
                        <CustomListItem>
                            <Caption sx={{ textAlign: 'left' }}>
                                Препятствиях, которые мешают команде работать более эффективно и создавать хорошую атмосферу.
                            </Caption>
                        </CustomListItem>
                    </List>
                    <Caption sx={{ textAlign: 'left' }}>После прохождения опроса, вы получите персональные рекомендации с предложенными способами поведения, которые могут помочь вашей команде повысить эффективность и атмосферу.</Caption>
                </StepInnerBlock>
            </StepBlock>
            

            <StepBlock>
                <StepTitleBlock><Coin>2</Coin><StepTitle>Отправьте опросник членам команды</StepTitle></StepTitleBlock>

                <StepInnerBlock>
                    <Caption sx={{ textAlign: 'left' }}>После того, как вы пройдете опросник, вы получите ссылку, чтобы разослать опросник всем участникам вашей команды.</Caption>
                    <Caption sx={{ textAlign: 'left' }}>Объясните команде цель опроса и установите разумный срок для прохождения опроса.</Caption>
                </StepInnerBlock>
            </StepBlock>
            

            <StepBlock>
                <StepTitleBlock><Coin>3</Coin><StepTitle>Соберите и изучите результаты</StepTitle></StepTitleBlock>

                <StepInnerBlock>
                    <Caption sx={{ textAlign: 'left' }}>Дождитесь завершения опроса всеми участниками.</Caption>
                    <Caption sx={{ textAlign: 'left' }}>После этого вы получите все ответы (анонимно) и рекомендации, основанные на их ответах.</Caption>
                    <List>
                        <Caption sx={{ textAlign: 'left' }}>Проанализируйте результаты:</Caption>
                        <CustomListItem>
                            <Caption sx={{ textAlign: 'left' }}>
                                Ищите общие темы и повторяющиеся препятствия.
                            </Caption>
                        </CustomListItem>
                        <CustomListItem>
                            <Caption sx={{ textAlign: 'left' }}>
                                Обратите внимание на совпадения в предложенных способах поведения.
                            </Caption>
                        </CustomListItem>
                    </List>
                </StepInnerBlock>
            </StepBlock>
            

            <StepBlock>
                <StepTitleBlock><Coin>4</Coin><StepTitle>Проведите командную встречу</StepTitle></StepTitleBlock>

                <StepInnerBlock>
                    <Caption sx={{ textAlign: 'left' }}>Организуйте встречу со всей командой.</Caption>
                    <List>
                        <Caption sx={{ textAlign: 'left' }}>Подготовьте материалы:</Caption>
                        <CustomListItem>
                            <Caption sx={{ textAlign: 'left' }}>
                                Обобщенные результаты опроса.
                            </Caption>
                        </CustomListItem>
                        <CustomListItem>
                            <Caption sx={{ textAlign: 'left' }}>
                                Список рекомендуемых привычек – способов поведения.
                            </Caption>
                        </CustomListItem>
                    </List>
                    <Caption sx={{ textAlign: 'left' }}>После этого вы получите все ответы (анонимно) и рекомендации, основанные на их ответах.</Caption>
                </StepInnerBlock>
            </StepBlock>
            

            <StepBlock>
                <StepTitleBlock><Coin>5</Coin><StepTitle>Совместно выберите способы поведения</StepTitle></StepTitleBlock>

                <StepInnerBlock>
                    <List>
                        <Caption sx={{ textAlign: 'left' }}>Обсудите предлагаемые способы поведения:</Caption>
                        <CustomListItem>
                            <Caption sx={{ textAlign: 'left' }}>
                                Какие из них наиболее актуальны для вашей команды?
                            </Caption>
                        </CustomListItem>
                        <CustomListItem>
                            <Caption sx={{ textAlign: 'left' }}>
                                Какие принесут наибольшую пользу
                            </Caption>
                        </CustomListItem>
                    </List>

                    <Caption sx={{ textAlign: 'left' }}>Есть ли идеи о собственных способах поведения или редактировании предложенных? Вы можете внести свои способы поведения.</Caption>
                    
                    <List>
                        <Caption sx={{ textAlign: 'left' }}>Совместно определите:</Caption>
                        <CustomListItem>
                            <Caption sx={{ textAlign: 'left' }}>
                                5-7 положительных привычек, которые вы будете внедрять
                            </Caption>
                        </CustomListItem>
                        <CustomListItem>
                            <Caption sx={{ textAlign: 'left' }}>
                                5-7 отрицательных привычек, от которых вы будете избавляться.
                            </Caption>
                        </CustomListItem>
                    </List>

                    <Caption sx={{ textAlign: 'left' }}>Убедитесь, что все согласны – ваша команда должна понимать и принимать выбранные способы поведения.</Caption>

                </StepInnerBlock>
            </StepBlock>
        </Wrapper>
    );
};

export default QuizInstructions;
