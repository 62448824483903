import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import {Button, IconButton, InputBase, Typography, styled} from "@mui/material";
import {colors} from "../styles/globalStyles";
import {ReactComponent as ColoredDot} from '../images/colored_dot.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import {useParams} from "react-router-dom";
import {createClientLog, getTeamValues, postTeamValues, updateTeamValues} from "../api/adminPanel";
import ResultsValue from "../components/resultsValue";

const Buttons = styled('nav')({
    marginBottom: 17,
    padding: 3,

    display: 'flex',

    background: colors.transparentMain,
    borderRadius: 23,
})

const StyledButton = styled(Button)({
    width: '100%',
    height: 52,

    fontSize: 12,
    fontWeight: 700,
    lineHeight: '100%',
    color: colors.main,

    borderRadius: 20,

    '&.active': {
        background: colors.main,
        color: colors.white,
        '&:hover': {
            backgroundColor: colors.main,
            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0 0)',
        },
    },
    '&:hover': {
        background: colors.transparentMain,
    },
})

const LargeButton = styled(Button)({
    padding: 22,
    borderRadius: 23
})

const ValueCard = styled("div")({
    padding: 10,
    borderRadius: 23,
    backgroundColor: colors.transparentMain,
    display: "flex",
    flexDirection: "column",
    gap: 5
})

const Title = styled("div")({
    display: "flex",
    alignItems: "center",
    gap: 5,
    paddingLeft: "7px"
})

const StyledTextInput = styled(InputBase)({
    width: "100%",
    fontSize: 20,

    "& input": {
        border: "2px solid transparent",
        paddingLeft: 5,
        borderRadius: 10,
    },

    "& :first-child": {
        border: `2px solid transparent`,
        paddingLeft: 5,
        "& :focus": {
            border: `2px solid ${colors.darkBlue}`,
            borderRadius: 10,
        },
    },

    "& :focus": {
        border: `2px solid ${colors.darkBlue}`,
        borderRadius: 10,
    },

})

const Description = styled(InputBase)({
    width: "100%",
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: 400,

    "& :first-child": {
        border: `2px solid transparent`,
        paddingLeft: 5,
        "& :focus": {
            border: `2px solid ${colors.darkBlue}`,
            borderRadius: 10,
        },
    },

    "& input": {
        border: "2px solid transparent",
        paddingLeft: 5,
        borderRadius: 10,
    },

    "& :focus": {
        border: `2px solid ${colors.darkBlue}`,
        borderRadius: 10,
    }
})

const BehaviorList = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 5,
})

const BehaviorItem = styled("div")({
    padding: 20,
    borderRadius: 23,
    backgroundColor: colors.white,
    display: "flex",
    alignItems: "flex-start",
    gap: 5
});

const SurveyValueTitle = styled(Typography)({
    lineHeight: 1,
    letterSpacing: "0.01rem",
    fontWeight: 800,
    fontSize: 34,
    textAlign: 'center',
    color: colors.darkBlue,
    marginLeft: 'auto',
    marginRight: 'auto',
    textTransform: 'capitalize'
});

const Caption = styled(Typography)(() => ({
   padding: '0 20px',
   fontSize: 14,
   opacity: .8,
   textAlign: 'center',
   lineHeight: 1.5,

}));

const BehaviorManagementPage = () => {

    const [isPositive, setIsPositive] = useState(true);
    const [values, setValues] = useState([]);
    const [surveyValues, setSurveyValues] = useState([]);
    const {teamId, companyId} = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [isValidating, setIsValidating] = useState(false);
    const [status, setStatus] = useState("");

    const isUserLoggedFromTelegram = Boolean(window.Telegram?.WebApp?.initDataUnsafe?.user);

    useEffect(() => {
        getTeamValues(teamId)
            .then(r => {
                if (r.data?.values?.length !== 0) {
                    const sortedValues = [...r.data?.values].sort((a, b) => a.id - b.id)
                    sortedValues.forEach(obj => {
                        obj.behavior.sort((a, b) => a.id - b.id);
                    });
                    setValues(sortedValues);
                }
                else if (r.data?.values_from_survey.length !== 0) {
                    setSurveyValues(r.data?.values_from_survey);
                }
            })
            .catch((err) => {
                console.error("Ошибка получения ценностей команды", err);
            });
    }, []);


    const addValue = () => {
        setValues((prev) => {
            const newArr = [...prev];
            newArr.push({id: "-1", name: "", behavior: [], is_positive: isPositive});
            return newArr;
        });
    }

    const addBehavior = (behavior, valueIndex, setBehavior) => {
        let newArr = behavior

        newArr[valueIndex].behavior.push({id: "-1", title: "", description: ""})

        setBehavior([...newArr])
    }

    const deleteBehavior = (valueIndex, setBehavior, index) => {
        setBehavior(prev => {
            const newArr = prev

            newArr[valueIndex].behavior.splice(index, 1)
            return [...newArr]
        })
    }

    const deleteValue = (index) => {
        setValues((prev => {
            const newArr = [...prev]
            newArr.splice(index, 1)
            return [...newArr]
        }))
    }

    const onTextFieldChange = (value, index) => {

        setValues(prev => {
            const newArr = [...prev];
            newArr[index].name = value
            return newArr
        })
    }

    const onBehaviorTextFieldChange = (values, valueIndex, setValues, index, value, key) => {
        let newArr = values
        newArr[valueIndex].behavior[index][key] = value

        setValues([...newArr])
    }

    const onSaveData = (valuesArray) => {
        setIsValidating(true)
        if (validateData(valuesArray)) {
            setIsLoading(true)
            updateTeamValues(valuesArray, teamId).then(r => {
                const sortedValues = [...r.data].sort((a, b) => a.id - b.id)
                sortedValues.forEach(obj => {
                    obj.behavior.sort((a, b) => a.id - b.id);
                });
                setValues(sortedValues)
                setIsLoading(false)
                setIsValidating(false)
                setStatus("SUCCESS")
                setTimeout(() => setStatus(""), 2500)
            }).catch((e) => {
                createClientLog(e)
                setStatus("ERROR_NET")
            })
        } else {
            setStatus("ERROR_VAL")
        }

    }

    const mapValues = (isPositive) => {

        return (
            values?.filter(value => value.is_positive === isPositive).map((value, index) => (
                <ValueCard key={index}>
                    <Title>
                        <ColoredDot
                            fill={isPositive ? colors.green : colors.red}
                        />
                        <StyledTextInput
                            placeholder={"Введите название ценности"}
                            value={value.name}
                            multiline
                            onChange={(e) => onTextFieldChange(e.target.value, values.indexOf(value))}/>
                        <IconButton onClick={() => deleteValue(values.indexOf(value))}>
                            <DeleteIcon sx={{color: colors.darkBlue}}/>
                        </IconButton>
                    </Title>
                    <BehaviorList>
                        {mapBehaviors(values, values.indexOf(value), setValues)}
                        <LargeButton variant={"light"}
                                     onClick={() => addBehavior(values, values.indexOf(value), setValues)}>
                            + Добавить
                        </LargeButton>
                    </BehaviorList>
                </ValueCard>
            ))
        )
    };

    const mapBehaviors = (values, valueIndex, setValues) => {
        return (
            <>
                {values[valueIndex]?.behavior.map((behavior, index) => <div>
                    <Behavior title={behavior.title}
                              description={behavior.description}
                              onBehaviorNameChange={(e) => onBehaviorTextFieldChange(values, valueIndex, setValues, index, e.target.value, e.target.name)}
                              onDelete={() => deleteBehavior(valueIndex, setValues, index)}/>
                </div>)}
            </>
        )
    }


    const [behaviorsData, setBehaviorsData] = useState({});
    
    useEffect(() => {
        setBehaviorsData(surveyValues?.flatMap(item => item.behavior).reduce((acc, {id}) => ({...acc, [id]: false}), {}));
    }, [surveyValues]);
    
    const handleSelectedBehaviorChange = (behaviorId) => {
        setBehaviorsData(prev => ({
            ...prev,
            [behaviorId]: !behaviorsData[behaviorId]
        }))
    }
    
    const handleSubmitBehaviors = () => {
        if (Object.entries(behaviorsData).every(([_, isAdded]) => (isAdded === false))) {
            return;
        }

        const data = {
            team_id: teamId,
            behaviors: {...behaviorsData}
        };
    
        postTeamValues(data)
            .then((res) => {
                const sortedValues = [...res.data].sort((a, b) => a.id - b.id)
                sortedValues.forEach(obj => {
                    obj.behavior.sort((a, b) => a.id - b.id);
                });
                setValues(sortedValues);
                setSurveyValues([]);
            })
    }


    return (
        <Layout link={`/admin/company/${companyId}/team/${teamId}`}>
            <Buttons onClick={() => setIsPositive(!isPositive)}>
                <StyledButton className={isPositive ? 'active' : null}>Положительные</StyledButton>
                <StyledButton className={isPositive ? null : 'active'}>Отрицательные</StyledButton>
            </Buttons>
            {values.length === 0 && surveyValues?.length !== 0 && (
                <>
                    {surveyValues?.filter(value => value.is_positive === isPositive).map(value => <ResultsValue
                        behaviorsData={behaviorsData} handleChange={handleSelectedBehaviorChange}
                        key={value.id} value={value}
                    />)}

                    <Caption>После утверждения выбранные ценности будут закреплены за командой</Caption>

                    <LargeButton variant={"colored"} onClick={handleSubmitBehaviors}>
                        Утвердить ценности для команды
                    </LargeButton>
                </>
            )}
            
            {values.length === 0 && surveyValues?.length === 0 && isUserLoggedFromTelegram && (
                <Caption>
                    Рекомендованные ценности появятся после прохождения опроса участниками команды
                </Caption>
            )}
            
            <LargeButton variant={"colored"} onClick={() => addValue(isPositive)}>
                + Добавить
            </LargeButton>
            {mapValues(isPositive)}

            {
                status !== "" && (
                    <Typography sx={{color: status === "SUCCESS" ? colors.green : colors.red}}>
                        {status === "SUCCESS" ? "Данные успешно сохранены" : null}
                        {status === "ERROR_VAL" ? "Не все поля заполнены" : null}
                        {status === "ERROR_NET" ? "Ошибка сети" : null}
                    </Typography>
                )
            }

            <LargeButton disabled={isLoading} variant={"colored"} onClick={() => onSaveData(values)}>
                Сохранить
            </LargeButton>
        </Layout>
    )
}

const Behavior = ({title, description, onBehaviorNameChange, onDelete}) => {

    const [isOpen, setIsOpen] = useState(false);
    return (
        <BehaviorItem>
            <div style={{display: "flex", flexDirection: "column", gap: 2}}>
                <StyledTextInput value={title}
                                 name={"title"}
                                 multiline
                                 onChange={onBehaviorNameChange}
                                 placeholder={"Введите название поведения"}/>
                {
                    isOpen ? <Description value={description}
                                          name={"description"}
                                          multiline
                                          onChange={onBehaviorNameChange}
                                          placeholder={"Введите описание"}/>
                        : null
                }
            </div>
            <IconButton onClick={() => setIsOpen(!isOpen)}>
                <InfoIcon sx={{color: colors.darkBlue}}/>
            </IconButton>
            <IconButton onClick={onDelete}>
                <DeleteIcon sx={{color: colors.darkBlue}}/>
            </IconButton>
        </BehaviorItem>
    )
}

function validateData(data) {
    for (const obj of data) {
        if (!obj.name.trim()) {
            return false;
        }
        for (const behaviorObj of obj.behavior) {
            if (!behaviorObj.title.trim()) {
                return false;
            }
        }
    }
    return true;
}

export default BehaviorManagementPage