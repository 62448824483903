import { Navigate, Route, Routes } from 'react-router-dom';
import WelcomePage from './pages/welcomePage';
import MainPanel from './pages/mainPanel';
import MySessions from './pages/mySessions';
import ProgressPage from './pages/progressPage';
import TrackerPage from './pages/trackerPage';
import SupportCenterPage from './pages/supportCenterPage';
import TeammateBehaviorsPage from './pages/teammateBehaviorsPage';
import TelegramSubscriptionPage from './pages/telegramSubscriptionPage';
import CustomRouter from './CustomRouter';
import customHistory from './history';
import ProfilePage from './pages/profilePage';
import { Suspense } from 'react';
import MySessionsSkeleton from './components/skeletons/mySessionSkeleton';
import TrackerPageSkeleton from './components/skeletons/trackerPageSkeleton';
import MainPanelSkeleton from './components/skeletons/mainPanelSkeleton';
import AdminPanel from './pages/adminPanel';
import TeamControlPage from './pages/teamControlPage';
import SessionHistory from './pages/sessionHistory';
import TeammatesPage from './pages/teammatesPage';
import MaxScoreEditPage from './pages/maxScoreEditPage';
import BehavoirManagementPage from './pages/behavoirManagementPage';
import { useRecoilValue } from 'recoil';
import { AdminUserAtom } from './recoil/atoms/adminPanelAtoms';
import RegistrationPage from "./pages/registrationPage";
import QuizPage from "./pages/quizPage";
import TrialEndPage from "./pages/trialEndPage";
import TeamSurveyResultsPage from "./pages/teamSurveyResults";

function App() {

    // console.log(window.Telegram.WebApp);

    return (
        <CustomRouter history={customHistory}>
            <Routes>
                <Route path={'/auth'} element={<WelcomePage />} />
                <Route path={'/registration'} element={<RegistrationPage />} />
                <Route path={'/quiz'} element={<QuizPage />} />
                <Route path={'/trial-end'} element={<TrialEndPage />} />
                <Route
                    path={'/main'}
                    element={
                        <RequestAuth>
                            <Suspense fallback={<MainPanelSkeleton />}>
                                <MainPanel />
                            </Suspense>
                        </RequestAuth>
                    }
                />
                <Route
                    path={'/'}
                    element={
                        <RequestAuth>
                            <Suspense fallback={<MainPanelSkeleton />}>
                                <MainPanel />
                            </Suspense>
                        </RequestAuth>
                    }
                />
                <Route
                    path={'/my-sessions'}
                    element={
                        <RequestAuth>
                            <Suspense fallback={<MySessionsSkeleton link={'/'} />}>
                                <MySessions />
                            </Suspense>
                        </RequestAuth>
                    }
                />
                <Route
                    path={'/progress'}
                    element={
                        <RequestAuth>
                            <ProgressPage />
                        </RequestAuth>
                    }
                />
                <Route
                    path={'/tracker'}
                    element={
                        <RequestAuth>
                            <Suspense fallback={<TrackerPageSkeleton link={'/'} />}>
                                <TrackerPage />
                            </Suspense>
                        </RequestAuth>
                    }
                />
                <Route
                    path={'/support'}
                    element={
                        <RequestAuth>
                            <SupportCenterPage />
                        </RequestAuth>
                    }
                />
                <Route
                    path={'/telegram'}
                    element={
                        <RequestAuth>
                            <TelegramSubscriptionPage />
                        </RequestAuth>
                    }
                />
                <Route
                    path={'/teammate-behaviors/:userId'}
                    element={
                        <RequestAuth>
                            <Suspense fallback={<div></div>}>
                                <TeammateBehaviorsPage />
                            </Suspense>
                        </RequestAuth>
                    }
                />
                <Route
                    path={'/profile'}
                    element={
                        <RequestAuth>
                            <Suspense fallback={<div></div>}>
                                <ProfilePage />
                            </Suspense>
                        </RequestAuth>
                    }
                />
                <Route
                    path={'/admin'}
                    element={
                        <AdminPanelAuth>
                            <AdminPanel data-testid="admin-page" />
                        </AdminPanelAuth>
                    }
                />
                <Route
                    path={'/admin/company/:companyId/team/:teamId'}
                    element={
                        <AdminPanelAuth>
                            <TeamControlPage />
                        </AdminPanelAuth>
                    }
                />
                <Route
                    path={'/admin/company/:companyId/team/:teamId/session-history'}
                    element={
                        <AdminPanelAuth>
                            <SessionHistory />
                        </AdminPanelAuth>
                    }
                />
                <Route
                    path={'/admin/company/:companyId/team/:teamId/users'}
                    element={
                        <AdminPanelAuth>
                            <Suspense fallback={<div></div>}>
                                <TeammatesPage />
                            </Suspense>
                        </AdminPanelAuth>
                    }
                />
                <Route
                    path={'/admin/company/:companyId/team/:teamId/behaviors'}
                    element={
                        <AdminPanelAuth>
                            <BehavoirManagementPage />
                        </AdminPanelAuth>
                    }
                />
                <Route
                    path={'/admin/company/:companyId/team/:teamId/survey-results'}
                    element={
                        <AdminPanelAuth>
                            <TeamSurveyResultsPage />
                        </AdminPanelAuth>
                    }
                />
                <Route
                    path={'/admin/company/:companyId/team/:teamId/max-score'}
                    element={
                        <AdminPanelAuth>
                            <MaxScoreEditPage />
                        </AdminPanelAuth>
                    }
                />
            </Routes>
        </CustomRouter>
    );
}

const RequestAuth = ({ children }) => {
    if (localStorage.getItem('auth-token')) return children;
    else return <Navigate to={'/auth'} />;
};

const AdminPanelAuth = ({ children }) => {
    const adminUser = useRecoilValue(AdminUserAtom);

    if (!localStorage.getItem('auth-token')) {
        return <Navigate to={'/auth'} />;
    }
    if (adminUser.isStaff === true || adminUser.appAdminPanel === true) {
        return children;
    } else {
        return <Navigate to={'/auth'} />;
    }
};

export default App;
